export function carousel($el, options = {}) {
  options = {
    handlersContainerSelector: ".js-carousel-handlers",
    slidesContainerSelector: ".js-carousel-slides",
    ...options,
  };

  if (typeof $el === "string") {
    $el = document.querySelector($el);
  }

  const instance = {
    $el: $el,
    $handlersContainerEl: $el.querySelector(options.handlersContainerSelector),
    $handlersEls: [],
    handlersLibrary: {},
    $slidesContainerEl: $el.querySelector(options.slidesContainerSelector),
    $slidesEls: [],
    slidesLibrary: {},
    resizeObserver: null,
    activeSlideId: null,
  };

  instance.$handlersEls = Array.from(instance.$handlersContainerEl.querySelectorAll("[data-slide-id]"));
  instance.$slidesEls = Array.from(instance.$slidesContainerEl.querySelectorAll("[data-slide-id]"));
  instance.resizeObserver = new ResizeObserver((entries) => resizeSlidesContainer());

  instance.$handlersEls.forEach((el) => {
    instance.handlersLibrary[el.dataset.slideId] = el;
  });

  instance.$slidesEls.forEach((el) => {
    instance.slidesLibrary[el.dataset.slideId] = el;
    instance.resizeObserver.observe(el);
  });

  activeSlide(instance.$handlersEls[0].dataset.slideId);
  activeHandler(instance.$handlersEls[0].dataset.slideId);

  function activeHandler(slideId) {
    instance.$handlersEls.forEach((el) => el.classList.remove("active"));
    instance.handlersLibrary[slideId].classList.add("active");
  }

  function activeSlide(slideId) {
    instance.activeSlideId = slideId;
    instance.$slidesEls.forEach((el) => el.classList.remove("active"));
    instance.slidesLibrary[slideId].classList.add("active");
    resizeSlidesContainer();
  }

  function resizeSlidesContainer() {
    const activeSlide = instance.$slidesEls.find((el) => el.classList.contains("active"));
    if (activeSlide) {
      const rect = activeSlide.getBoundingClientRect();
      instance.$slidesContainerEl.style.height = `${rect.height}px`;
    }
  }

  function onHandlerClick(e) {
    const slideId = e.currentTarget.dataset.slideId;
    activeSlide(slideId);
    activeHandler(slideId);
  }

  instance.$handlersEls.forEach((handlerEl) => {
    handlerEl.addEventListener("click", onHandlerClick);
  });

  window.addEventListener("resize", () => {
    resizeSlidesContainer();
  });

  return instance;
}

import { carousel } from "./carousel.js";
import { tns } from "tiny-slider/src/tiny-slider";
import { slideDown, slideUp } from "./slideToggle.js";

const mdMediaQuery = window.matchMedia("(max-width: 768px)");

const $slidableCards = document.querySelectorAll(".advantages-section__card");

function openCard(cardEl) {
  cardEl.classList.add("is-open");
  slideDown(cardEl.querySelector(".advantages-section__card-body"), 230);
}

function closeCard(cardEl) {
  cardEl.classList.remove("is-open");
  slideUp(cardEl.querySelector(".advantages-section__card-body"), 230);
}

function toggleCard(cardEl) {
  if (mdMediaQuery.matches) {
    if (cardEl.classList.contains("is-open")) {
      closeCard(cardEl);
    } else {
      openCard(cardEl);
    }
  }
}

function toggleAllCards() {
  $slidableCards.forEach((cardEl) => {
    if (mdMediaQuery.matches) {
      closeCard(cardEl);
    } else {
      openCard(cardEl);
    }
  });
}

mdMediaQuery.addEventListener("change", () => {
  toggleAllCards();
});

toggleAllCards();

$slidableCards.forEach((cardEl) => {
  cardEl.querySelector(".advantages-section__card-header").addEventListener("click", () => {
    toggleCard(cardEl);
  });
});

window.addEventListener("click", (event) => {
  if (event.target.hash) {
    const target = document.querySelector(event.target.hash);
    if (target) {
      target.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      event.preventDefault();
    }
  }

  if (event.target.hash === "#letter") {
    setTimeout(() => {
      document.querySelector('[name="message"]').focus();
    }, 1000);
  }
});

const ourCasesCarousel = carousel(".js-our-cases-carousel");

const solutionsSlider = tns({
  container: ".js-solutions-slider",
  autoplay: false,
  autoWidth: false,
  items: 1,
  gutter: 20,
  loop: false,
  mouseDrag: true,
  edgePadding: 200,
  rewind: true,
});

const advantagesOfRORSlider = tns({
  container: ".js-advantages-ror-slider",
  autoplay: false,
  autoWidth: false,
  items: 1,
  gutter: 20,
  loop: false,
  mouseDrag: true,
  edgePadding: 200,
  rewind: true,
  responsive: {
    768: {
      items: null,
      fixedWidth: 470,
    },
  },
});

const testimonialsSlider = tns({
  container: ".js-testimonials-slider",
  autoplay: false,
  items: 1,
  gutter: 20,
  loop: false,
  mouseDrag: true,
  edgePadding: 200,
  rewind: true,
  responsive: {
    768: {
      items: null,
      fixedWidth: 700,
    },
  },
});

function getSiblings(el, selector) {
  const siblings = [];
  let sibling = el.parentNode.firstChild;
  for (; sibling; sibling = sibling.nextSibling) {
    if (sibling.nodeType !== 1 || sibling === el || (selector ? !sibling.matches(selector) : false)) {
      continue;
    }
    siblings.push(sibling);
  }
  return siblings;
}

const textCollapseToggleSelector = "[data-text-collapse-toggle]";

function showMoreToggleListener(event) {
  const toggler = event.target.closest(textCollapseToggleSelector);
  if (!toggler) {
    return;
  }
  const siblings = getSiblings(toggler, "[data-text-collapsed]");
  const target = siblings[0];
  if (!target) {
    return;
  }
  const isCollapsed = target.dataset.textCollapsed === "true";
  target.dataset.textCollapsed = isCollapsed ? "false" : "true";
  toggler.innerText = isCollapsed ? toggler.dataset.showLessText : toggler.dataset.showMoreText;
}

document.addEventListener("click", showMoreToggleListener);
